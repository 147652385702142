import {
  BatchPrediction,
  Bolt,
  ChangeCircle,
  Contacts,
  Dns,
  EmojiPeople,
  History,
  InsightsSharp,
  LocalConvenienceStore,
  MultipleStop,
  OnlinePrediction,
  ProductionQuantityLimits,
  PublishedWithChanges,
  UploadFile,
  Water,
} from "@mui/icons-material";
import PATH from "../../../Routing/PATHS";

var loginData = localStorage.getItem("login");
var loginValue = JSON.parse(loginData);

const replenishmentSubMenus = [
  loginValue && loginValue.UserLevel === 2 && !loginValue.userModule.includes("NetSuite") && {
    menu: "Current",
    link: null,
    icon: <Dns />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Current Stock",
        link: PATH.NETSUITE_CURRENT_STOCK,
      },
    ],
  },
  {
    menu: "Excel File",
    link: PATH.REPLENISHMENT_EXCEL_FILE,
    icon: <UploadFile />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "File Upload Status",
        link: PATH.REPLENISHMENT_FILE_STATUS,
        icon: null,
      },
    ]
  },
  {
    menu: "Customer Data",
    link: null,
    icon: <Contacts />,
    multiLevel: true,
    multiMenus: [
      // {
      //   menuName: "SOH History",
      //   link: PATH.REPLENISHMENT_HISTORY_SOH,
      //   icon: <History />,
      // },
      {
        menuName: "Pipeline",
        link: PATH.REPLENISHMENT_PIPELINE,
        icon: <Water />,
        //multiLevel: false,
      },
      {
        menuName: "Store SOH",
        link: PATH.REPLENISHMENT_STORESOH,
        icon: <LocalConvenienceStore />,
        // multiLevel: false,
      },
      {
        menuName: "Store Sales",
        link: PATH.REPLENISHMENT_STORESALES,
        icon: <InsightsSharp />,
        //multiLevel: false,
      },
      {
        menuName: "MSL Level",
        link: PATH.REPLENISHMENT_MSLLEVEL,
        icon: <ProductionQuantityLimits />,
        //multiLevel: false,
      },
      // {
      //   menuName: "Quick Ref",
      //   link: PATH.REPLENISHMENT_QUICKREF,
      //   icon: <Bolt />,
      //   //multiLevel: false,
      // },
      // {
      //   menuName: "Sales Override",
      //   link: PATH.REPLENISHMENT_SALESOVERRIDE,
      //   icon: <ChangeCircle />,
      //   //multiLevel: false,
      // },
      // {
      //   menuName: "Stock Substitute",
      //   link: PATH.REPLENISHMENT_STOCKSUBSTITUTE,
      //   icon: <PublishedWithChanges />,
      //   //multiLevel: false,
      // },
      {
        menuName: "Store Grading",
        link: PATH.REPLENISHMENT_STORESTOCKMASTER,
        icon: <EmojiPeople />,
        // multiLevel: false,
      },

    ],
  },
  {
    menu: "Forecast",
    link: PATH.REPLENISHMENT_FORECAST,
    icon: <BatchPrediction />,
    //multiLevel: false,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Missing Stock Codes",
        link: PATH.REPLENISHMENT_MISSING_CODES,
        icon: null,
      },
    ]
  },
  {
    menu: "Allocations",
    link: null,
    icon: <MultipleStop />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Adjusted",
        link: PATH.REPLENISHMENT_ALLOCATIONS_ADJUSTED,
        icon: null,
      },
      // {
      //   menuName: "Standard",
      //   link: PATH.REPLENISHMENT_ALLOCATIONS_STANDARD,
      //   icon: null,
      // },
    ],
  },
].filter(Boolean);

export default replenishmentSubMenus;
