import React, { useEffect, useState } from "react";
import {
    ArrowForward,
    Close,
    Download,
    FilterList,
} from "@mui/icons-material";
import TableComponentPriceException from "../../Online/Orders/TableComponentPriceException";
import { httpclient } from "../../../utils/httpClient";
import {
    Box,
    Button,
    Card,
    Collapse,
    Grid,
    InputLabel,
    styled,
    TextField,
    Snackbar,
    Select,
    MenuItem,
    FormControl,
    CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { CSVLink } from "react-csv";
import { dateFormatter } from "../../../utils/DataGrid/dateFormatter";
import { useLocation, useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [

    { id: "STORE_CODE", name: "Branch" },
    { id: "STOCK_CODE", name: "Style" },
    { id: "TRAN_QTY", name: "Sales Quantity" },
    { id: "TRAN_VALUE", name: "Sales Value Inc" },
    { id: "COST_VALUE", name: "Cost Value" },
    { id: "TRAN_DATE", name: "Transaction Date" },

];

const FilteredBox = styled(Box)(({ theme }) => ({
    background: "#f9f9f9",
    padding: "5px 10px",
    borderRadius: "5px",
    "& p": {
        margin: "0",
        marginRight: "10px",
        display: "inline-block",
        background: "#dedede",
        borderRadius: "10px",
        padding: "2px 5px",
    },
    "& svg": {
        fontSize: "15px",
        cursor: "pointer",
        position: "relative",
        top: "3px",
        background: theme.palette.primary.dark,
        color: "#fff",
        borderRadius: "50%",
        padding: "2px",
        marginLeft: "2px",
    },
}));

const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
        margin: "0",
    },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const StoreSales = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [viewDetails, setViewDetails] = useState({});

    const [rows, setRows] = useState([]);
    const [branch, setBranch] = useState([]);
    const [rowsExport, setRowsExport] = useState([]);
    const [rowChecked, setRowChecked] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingExp, setLoadingExp] = useState(false);
    const [singleLoading, setSingleLoading] = useState(false);
    const [direction, setDirection] = useState(false);
    const [currentColumn, setCurrentColumn] = useState("");
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(
        configRowPerPage && configRowPerPage
            ? configRowPerPage && configRowPerPage
            : 20
    );

    const [rowsPerPage, setRowsPerPage] = useState(
        configRowPerPage && configRowPerPage
            ? configRowPerPage && configRowPerPage
            : 20
    );
    const [total, setTotal] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);
    const [state, setState] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");

    const [filterData, setFilterData] = useState({
        stockCode: "",
        style: "",
        from: null,
        to: null,
        remove: false,
    });

    const [submittedData, setSubmittedData] = useState({
        stockCode: "",
        style: "",
        from: null,
        to: null,
        submit: false,
    });

    // useEffect(() => {
    //   getOrdersException();
    // }, []);

    useEffect(() => {
        if (
            filterData.stockCode === "" &&
            filterData.style === "" &&
            filterData.from === "" &&
            filterData.to === ""
        ) {
            setSubmittedData({
                ...submittedData,
                submit: false,
            });
        }

        if (filterData.stockCode === " ") filterData.stockCode = "";
        if (filterData.style === " ") filterData.style = "";
        if (filterData.from === " ") filterData.from = "";
        if (filterData.to === " ") filterData.to = "";

        filterData.remove === true && handleFilter();
    }, [filterData]);

    useEffect(() => {
        let currentpolicy = JSON.parse(
            localStorage.getItem("storesales-filter")
        );
        currentpolicy !== null && setFilterData(currentpolicy);

        currentpolicy == null
            ? getOrdersException()
            : currentpolicy.stockCode == "" &&
                currentpolicy.style == "" &&
                currentpolicy.from == "" &&
                currentpolicy.to == "" &&
                currentpolicy.removed == false
                ? getOrdersException()
                : console.log("orders price exception");
    }, []);


    useEffect(() => {
        if (
            location.state?.startDate
        ) {
            setState(location.state?.startDate);
        }
    }, [
        location.state?.startDate !== null,
    ]);

    useEffect(() => {
        if (
            state
        ) {
            filterData.from = state
            setTimeout(() => {
                handleFilter();
                navigate("#", { replace: true });
            }, 1500);
        }
    }, [state]);


    const getOrdersException = () => {
        setLoading(true);
        httpclient
            .get(`replenishment/store-sales?pagination=${rowsPerPage}`)
            .then(({ data }) => {
                if (data) {
                    setRows(data.data);
                    setBranch(data.branchList);
                    setTotal(data.meta.total);
                    setRowsPerPage(parseInt(data.meta.per_page));
                    setPage(data.meta.current_page);
                    setFrom(data.meta.from);
                    setTo(data.meta.to);
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location = "/login";
                }
            })
    };

    useEffect(() => {
        if (total !== "" && total < 180000 && (filterData.from !== null && filterData.from !== "") && (filterData.to !== null && filterData.to !== "")) {
            getExportData()
        }
    }, [total, filterData]);

    const getExportData = () => {
        setLoadingExp(true);
        httpclient
            .get(`replenishment/store-sales?pagination=${total}&STORE_CODE=${filterData.stockCode}&STOCK_CODE=${filterData.style
                }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                }`)
            .then(({ data }) => {
                if (data) {
                    setRowsExport(data.data);
                    setLoadingExp(false);
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location = "/login";
                }
            })
    };

    //   const handleView = (row) => {
    //       setSingleLoading(true);
    //       setOpenViewDialog(true);
    //       httpclient
    //         .get(`new-system/exceptional-report/export-order/${row.Id}`)
    //         .then(({ data }) => {
    //           if (data) {
    //             setViewDetails(data.data);
    //             setSingleLoading(false);
    //           }
    //         })
    //   };

    const sendDetails = (callback) => {
        if (callback.open === false) {
            setOpenViewDialog(false);
            setViewDetails({});
        }
    };

    const handleRefetch = () => {
        getOrdersException();
    }

    const handleFilter = () => {
        setSubmittedData({
            ...submittedData,

            stockCode: filterData.stockCode,
            style: filterData.style,
            from: filterData.from,
            to: filterData.to,
            submit: true,
        });
        filterData.remove = true;
        localStorage.setItem(
            "storesales-filter",
            JSON.stringify(filterData)
        );
        setLoading(true);
        if (
            filterData.stockCode ||
            filterData.style ||
            filterData.from ||
            filterData.to
        ) {
            httpclient
                .get(
                    `replenishment/store-sales?STORE_CODE=${filterData.stockCode}&STOCK_CODE=${filterData.style
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&pagination=${rowsPerPage}&page=${1}`
                )
                .then(({ data }) => {
                    if (data.success) {
                        setRows(data.data);
                        setBranch(data.branchList);
                        setTotal(data.meta.total);
                        setRowsPerPage(data.meta.per_page);
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                });
        } else {
            getOrdersException();
        }
    };

    const hadleFilterOpen = () => {
        setFilterOpen((prev) => !prev);
    };

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        setFilterData({
            ...filterData,
            [name]: value,
            remove: false,
        });
    };

    const handleRemove = (data) => {

        if (data === "from") {
            setRowsExport([]);
            setFilterData({
                ...filterData,
                from: "",
                to: "",
                remove: true,
            });

            setSubmittedData({
                ...submittedData,
                from: "",
                to: "",
            });
        } else {
            setFilterData({
                ...filterData,
                [data]: "",
                remove: true,
            });

            setSubmittedData({
                ...submittedData,
                [data]: "",
            });
        }
    };

    const handleRowCheck = (e, row) => {
        const { checked } = e.target;
        if (checked === true) {
            setRowChecked([...rowChecked, row.orderID]);
        } else {
            let newData = rowChecked.filter((check) => {
                return check !== row.orderID;
            });
            setRowChecked(newData);
        }
    };

    const handleSort = (column) => {
        setDirection((prevDirection) => !prevDirection);
        setCurrentColumn(column);
        submittedData.submit
            ? httpclient
                .get(
                    `replenishment/store-sales?STORE_CODE=${filterData.stockCode}&STOCK_CODE=${filterData.style
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `replenishment/store-sales?sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                    }
                });
    };

    const handleChangePage = (e, page) => {
        setLoading(true);
        submittedData.submit
            ? httpclient
                .get(
                    `replenishment/store-sales?STORE_CODE=${filterData.stockCode}&STOCK_CODE=${filterData.style
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `replenishment/store-sales?direction=${direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        // setLoading(true);

        localStorage.setItem("configRowPerPage", event.target.value);

        submittedData.submit
            ? httpclient
                .get(
                    `replenishment/store-sales?STORE_CODE=${filterData.stockCode
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
                    }&pagination=${+event.target.value}&page=${page}`
                )
                .then(({ data }) => {
                    setLoading(true);
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `replenishment/store-sales?direction=${direction ? "asc" : "desc"
                    }&pagination=${+event.target.value}&page=${1}`
                )
                .then(({ data }) => {
                    setLoading(true);
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setPage(data.meta.current_page);
                        setLoading(false);
                    }
                });
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };


    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <Header>
                        <h1>List Store Sales</h1>
                    </Header>
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    {/* <Button
                        download
                        color="primary"
                        variant="contained"
                        href={`${window.location.origin}/php/api/replenishment/store-sales/export?startDate=${filterData.from}&endDate=${filterData.to}&STORE_CODE=${filterData.stockCode}&STOCK_CODE=${filterData.style}`}
                        // onClick={handleDownloadCsv}
                        disabled={(filterData.from && filterData.to) ? false : true}
                        style={{ marginRight: "10px" }}
                    >
                        Export <Download style={{ marginLeft: "5px" }} fontSize="small" />
                    </Button> */}


                    {(loadingExp || rowsExport.length === 0) ? (

                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "2px" }}
                            disabled={rowsExport.length === 0}
                        //onClick={handleExport}
                        //ref={buttonRef} // Assign the ref to the button
                        >
                            {/* {loadingExport ? "Generating Data to Export" : "Create Export"}{loadingExport ? `...` : <Download style={{ marginLeft: "5px" }} fontSize="small" />} */}
                            Export {loadingExp ?
                                <CircularProgress
                                    style={{
                                        marginLeft: "5px",
                                        height: "25px",
                                        width: "25px",
                                        color: "#f5f5f5"
                                    }} fontSize="small" />
                                :
                                <Download style={{ marginLeft: "5px" }} fontSize="small" />}
                        </Button>
                    ) : (
                        <CSVLink data={rowsExport} filename={"List store sales" + String(dateFormatter(Date.now())) + ".csv"}>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{ marginRight: "2px" }}
                            >
                                Export <Download style={{ marginLeft: "5px" }} fontSize="small" />
                            </Button>
                        </CSVLink>

                    )}

                    <Button sx={{ marginLeft: "5px" }} color="primary" variant="contained" onClick={hadleFilterOpen}>
                        Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
                    </Button>
                </Grid>
                {/* Filter */}
                <Grid item xs={12}>
                    <Collapse in={filterOpen}>
                        <Card>
                            <Box p={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Branch</InputLabel>
                                        {/* <TextField
                                            name="stockCode"
                                            value={filterData.stockCode}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            variant="outlined"
                                            fullWidth
                                        /> */}
                                        <FormControl fullWidth>
                                            <Select
                                                name="stockCode"
                                                value={filterData.stockCode}
                                                onChange={handleChangeFilter}
                                            >
                                                <MenuItem value={""}></MenuItem>
                                                {branch.map((b) => (
                                                    <MenuItem key={b.id} value={b.name}>
                                                        {b.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Style</InputLabel>
                                        <TextField
                                            name="style"
                                            value={filterData.style}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Start Date</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="from"
                                            type="date"
                                            value={filterData.from}
                                            onChange={(e) => handleChangeFilter(e)}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>End Date</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="to"
                                            type="date"
                                            value={filterData.to}
                                            onChange={(e) => handleChangeFilter(e)}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box textAlign={"right"}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleFilter}
                                            >
                                                Filter{" "}
                                                <ArrowForward
                                                    fontSize="small"
                                                    style={{ marginLeft: "5px" }}
                                                />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Collapse>
                </Grid>

                {submittedData.stockCode || submittedData.style || submittedData.from || submittedData.to ? (
                    <Grid item xs={12}>
                        <FilteredBox>
                            <span>Filtered: </span>

                            {submittedData.stockCode && (
                                <p>
                                    <span>Branch: {submittedData.stockCode}</span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("stockCode")}
                                    />
                                </p>
                            )}
                            {submittedData.style && (
                                <p>
                                    <span>Style: {submittedData.style}</span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("style")}
                                    />
                                </p>
                            )}

                            {(submittedData.from || submittedData.to) && (
                                <p>
                                    <span>
                                        Date Range: {submittedData.from} - {submittedData.to}
                                    </span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("from")}
                                    />
                                </p>
                            )}
                        </FilteredBox>
                    </Grid>
                ) : (
                    <Box></Box>
                )}
                {/* Filter */}
                <Grid item md={12} xs={12}>

                    <div style={{ display: "flex", flexDirection: "column", lineHeight: "1.0", fontFamily: "Poppins", alignItems: "flex-end" }}>
                        <small>Please filter data within short intervals(approx. 1 Month) before export.</small>
                        &nbsp;&nbsp;
                        <small>More than 150 thousands data couldnot be exported.</small>
                    </div>

                </Grid>

                <Grid item xs={12}>
                    <TableComponentPriceException
                        columns={columns}
                        rows={rows}
                        sort={true}
                        // handleView={handleView}
                        handleRefetch={handleRefetch}
                        handleSort={handleSort}
                        loading={loading}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleChangePage={handleChangePage}
                        handleRowCheck={handleRowCheck}
                        rowChecked={rowChecked}
                        //handleAccept={handleAccept}
                        //handleReject={handleReject}
                        direction={direction}
                        currentColumn={currentColumn}
                        page={page}
                        total={total && total}
                        fromTable={from}
                        toTable={to}
                        rowsPerPage={rowsPerPage}
                    />
                </Grid>
            </Grid>

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default StoreSales;
