import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import OptionMenu from "./OptionMenu";
import moment from "moment/moment";
import PropTypes from 'prop-types';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Sell,
  Sync,
  UnfoldMore,
} from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  "& svg": {
    position: "relative",
    top: "5px",
  },
  "&:last-child": {
    // paddingleft: 64,
    "& svg": {
      // display: 'none',
      color: theme.palette.primary.dark,
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, status }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: !status && theme.palette.action.hover,
  },
  backgroundColor:
    status === "In Queue"
      ? "#FFF4CC"
      : status === "In Progress"
        ? "#DDEBF6"
        : status === "Completed"
          ? "#DFF6DD"
          : status === "Failed"
            ? "#F8D7DA"
            : "inherit",

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress style={{ color: "primary" }} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 10,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "primary" }}
        >
          {`${Math.round(props.value1)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
  value1: PropTypes.number.isRequired,
};

export default function TableComponent(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [progress, setProgress] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

 
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.columns.map((col, index) =>

                <StyledTableCell>{col.name}</StyledTableCell>

              )}
            </TableRow>
          </TableHead>
          {props.loading ? (
            <TableBody
              sx={{ position: "relative", height: "365px", overflow: "hidden" }}
            >
              <Box p={3} sx={{ position: "absolute", width: "99%" }}>
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
              </Box>
            </TableBody>
          ) : (
            <TableBody>
              {props.rows.length > 0 ? (props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index1) => (
                  <StyledTableRow status={props.uploadStatus ? row.status : ""} key={Math.random()}>
                    {props.columns.map((col, index) =>
                      col.id === "sn" ? (
                        <StyledTableCell component="th" scope="row">
                          {page * rowsPerPage + index1 + 1}
                        </StyledTableCell>

                      ) :
                        col.id === "description" ||
                          col.id === "shortDescription" ||
                          col.id === "text" ? (
                          <StyledTableCell
                            align="left"
                            sx={{ minWidth: "200px" }}
                          >
                            {(
                              row[col.id].substring(0, 100) +
                              (row[col.id].length > 100 ? "..." : " ")
                            ).replaceAll(/<[^>]+>/g, "")}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell align = "left">
                            {col.id === "active" ? (
                              row[col.id] === true ? (
                                "Yes"
                              ) : (
                                "NO"
                              )
                            )
                              : col.id === "actions" ? (
                                // <StyledTableCell
                                //   align="right"
                                //   sx={{ minWidth: "220px" }}
                                // >
                                <>
                                  <Button
                                    sx={{ height: "30px" }}
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => props.getSingleHoliday(row.id)}
                                  >
                                    Edit
                                  </Button>
                                  {props.keyLoading &&
                                    props.deletingRow === row.id ? (
                                    <CircularProgress
                                      style={{
                                        height: "25px",
                                        width: "25px",
                                        marginLeft: "40px",
                                        marginRight: "30px",
                                        position: "relative",
                                        top: "10px",
                                      }}
                                    />
                                  ) : (
                                    <Button

                                      color="secondary"
                                      variant="outlined"
                                      onClick={() =>
                                        props.deleteSingleHoliday(row.id)
                                      }
                                      sx={{ marginLeft: "10px", height: "30px" }}
                                    >
                                      Delete
                                    </Button>
                                  )}
                                </>
                                // </StyledTableCell>
                              )
                                :
                                col.id === "createdDate" ? (
                                  moment(row[col.id]).format(
                                    "ddd, MMM Do YYYY, h:mm:ss a"
                                  )
                                ) : col.id === "date" ? (
                                  moment(row[col.id], "DD/MM/YYYY").format(
                                    "ddd, MMM Do YYYY"
                                  )
                                ) : col.id === "date" ? (
                                  moment(row[col.id]).format("ddd, MMM Do YYYY")
                                ) : col.id === "importDate" ? (
                                  moment(row[col.id]).format("ddd, MMM Do YYYY h:mm A"))
                                  : col.id === "missingCodes" ? (
                                    row
                                  ) : col.id === "status" ? (
                                    <>
                                      <strong>{row[col.id]}</strong>
                                      {/* {row[col.id] === "Failed" && (<Tooltip title={"Reprocess"}><Sync onClick={() => props.handleReprocess()} sx={{marginLeft:"10px"}}/></Tooltip>)} */}
                                      {(row[col.id] === "In Queue" || row[col.id] === "Failed") && (
                                        <div style={{ lineHeight: "0.5", marginTop: "10px", fontFamily: "Poppins" }}>
                                          <small>{row.statusmessage}</small>
                                        </div>
                                      )}
                                    </>

                                  ) : col.id === "progress" ? (
                                    <CircularProgressWithLabel value={row.status === "Failed" ? 0 : row.status === "In Queue" ? 0 : row.status === "Completed" ? 100 : progress} value1={row.progress} />
                                  ) : (
                                    row[col.id]
                                  )}
                          </StyledTableCell>
                        )
                    )}
                  </StyledTableRow>
                ))) : (
                <TableRow sx={{ position: "relative", height: "50px" }}>
                  <TableCell
                    sx={{
                      position: "absolute",
                      right: "50%",
                      borderBottom: "none",
                    }}
                  >
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* {props.footer !== false && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )} */}
    </Paper>
  );
}
