import React from "react";
import DataGridTablePage from "../../../Components/DataGridTablePage/DataGridTablePage";
import { useLocation } from "react-router-dom";

const fieldHeaderPairs = [
  { field: "STOCK_NAME", headerName: "Store Name" },
  { field: "STORE_CODE", headerName: "Store Code" },
  { field: "IsDC", headerName: "Is DC?" },
  { field: "StoreDC", headerName: "Store DC" },
  { field: "STORE_GRADE", headerName: "Store Grading" },
  { field: "Optimisation", headerName: "Optimisation" },
  { field: "StoreSize", headerName: "Store Size" },
  { field: "ExtraStock1", headerName: "Extra Stock 1" },
];

const filterColumn = [
  {
    field: "STOCK_NAME",
    label: "STOCK NAME",
    component: "AutocompleteField",
    url: "/replenishment/msl-levels",
    overrideDataField: "branchList",
  },
  // {
  //   field: "STORE_CODE",
  //   label: "STORE CODE",
  //   component: "TextField",
  // },
  // {
  //   field: "IsDC",
  //   label: "IS DC?",
  //   component: "SelectField",
  // },
  // {
  //   field: "StoreDC",
  //   label: "STORE DC",
  //   component: "TextField",
  // },
  // {
  //   field: "StoreGrading",
  //   label: "STORE GRADING",
  //   component: "TextField",
  // },
  // {
  //   field: "Optimisation",
  //   label: "OPTIMISATION",
  //   component: "TextField",
  // },
  // {
  //   field: "StoreSize",
  //   label: "STORE SIZE",
  //   component: "TextField",
  // },
  // {
  //   field: "ExtraStock1",
  //   label: "EXTRA STOCK 1",
  //   component: "TextField",
  // },
];

const ReplenishmentStoreStockMaster = () => {
  const location = useLocation();
  return (
    <DataGridTablePage
      headerTitle="List Store Grading"
      hideMenu={4}
      navigateState={location.state}
      exportFeature={true}
      // customIDKey="TRAN_ID"
      columns={fieldHeaderPairs}
      filterColumn={filterColumn}
      pageUrl="/replenishment/storegrade"
      columnsVisibility={{
        TRAN_ID: false,
      }}
    />
  );
};
export default ReplenishmentStoreStockMaster;
