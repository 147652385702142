import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import TableComponentBranch from "../../../Online/Stock/TableComponentBranch";
import { httpclient } from "../../../../utils/httpClient";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import ViewBranchDialog from "../../../Online/Stock/ViewBranchDialog";

const columns = [
  { id: "branchID", name: "ID" },
  { id: "branchCode", name: "Location Code" },
  { id: "branchName", name: "Location Name" },
  { id: "locationNotes", name: "Location Notes" },
  { id: "branchOrderPriority", name: "Pickup Order Priority" },
  { id: "inboundBranch", name: "Inbound Location" },
  { id: "pendingProcess", name: "Pending Process" },
  { id: "branchActive", name: "Active" },
  { id: "branchLastModifiedDate", name: "Last Modified" },
  // { id: "actions", name: "Actions" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const NetsuiteCurrentBranch = () => {
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [filterData, setFilterData] = useState({
    branchCode: "",
    branchName: "",
    inboundBranch: "",
    pendingProcess: "",
    active: "",
    lastModified: "",
    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    branchCode: "",
    branchName: "",
    inboundBranch: "",
    pendingProcess: "",
    active: "",
    lastModified: "",
    submit: false,
  });

  // useEffect(() => {
  //   getCurrentBranch();
  // }, []);

  useEffect(() => {
    if (
      filterData.branchCode === "" &&
      filterData.branchName === "" &&
      filterData.inboundBranch === "" &&
      filterData.pendingProcess === "" &&
      filterData.active === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.branchCode === " ") filterData.branchCode = "";
    if (filterData.branchName === " ") filterData.branchName = "";
    if (filterData.inboundBranch === " ") filterData.inboundBranch = "";
    if (filterData.pendingProcess === " ") filterData.pendingProcess = "";
    if (filterData.active === " ") filterData.active = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentBranch = JSON.parse(
      localStorage.getItem("netsuite_current_branch_filter")
    );
    currentBranch !== null && setFilterData(currentBranch);

    currentBranch == null
      ? getCurrentBranch()
      : currentBranch.branchCode == "" &&
        currentBranch.branchName == "" &&
        currentBranch.inboundBranch == "" &&
        currentBranch.pendingProcess == "" &&
        currentBranch.active == "" &&
        currentBranch.removed == false
      ? getCurrentBranch()
      : console.log("current branch!");
  }, []);

  const getCurrentBranch = () => {
    setLoading(true);
    httpclient
      .get(`/netsuite/v1/getLocations?pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success) {
          setRows(data.records.data);
          setTotal(data.records.total);
          setRowsPerPage(parseInt(data.records.per_page));
          setPage(data.records.current_page);
          setFrom(data.records.from);
          setTo(data.records.to);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };

  const handleView = (row) => {
    setOpenViewDialog(true);
    setViewDetails(row);
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      branchCode: filterData.branchCode,
      branchName: filterData.branchName,
      inboundBranch: filterData.inboundBranch,
      pendingProcess: filterData.pendingProcess,
      active: filterData.active,
      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("netsuite_current_branch_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.branchCode ||
      filterData.branchName ||
      filterData.inboundBranch ||
      filterData.pendingProcess ||
      filterData.active
    ) {
      httpclient
        .get(
          `netsuite/v1/getLocations?branchCode=${filterData.branchCode}&branchName=${
            filterData.branchName
          }&inboundBranch=${filterData.inboundBranch}&pendingProcess=${
            filterData.pendingProcess
          }&branchActive=${
            filterData.active
          }&pagination=${rowsPerPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.success) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRowsPerPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);
          }
        });
    } else {
      getCurrentBranch();
    }
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };

  const handleSort = (column) => {
    setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpclient
          .get(
            `netsuite/v1/getLocations?branchCode=${filterData.branchCode}&branchName=${
              filterData.branchName
            }&inboundBranch=${filterData.inboundBranch}&pendingProcess=${
              filterData.pendingProcess
            }&branchActive=${filterData.active}&sort_by=${column}&direction=${
              !direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRowsPerPage(parseInt(data.records.per_page));
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `netsuite/v1/getLocations?sort_by=${column}&direction=${
              !direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.records.data);
            }
          });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    (submittedData.submit || currentColumn)
      ? httpclient
          .get(
            `netsuite/v1/getLocations?branchCode=${filterData.branchCode}&branchName=${
              filterData.branchName
            }&inboundBranch=${filterData.inboundBranch}&pendingProcess=${
              filterData.pendingProcess
            }&branchActive=${
              filterData.active
            }&sort_by=${currentColumn}&direction=${
              direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRowsPerPage(parseInt(data.records.per_page));
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `netsuite/v1/getLocations?direction=${
              direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRowsPerPage(parseInt(data.records.per_page));
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setLoading(true);

    localStorage.setItem("configRowPerPage", event.target.value);

    (submittedData.submit || currentColumn)
      ? httpclient
          .get(
            `netsuite/v1/getLocations?branchCode=${filterData.branchCode}&branchName=${
              filterData.branchName
            }&inboundBranch=${filterData.inboundBranch}&pendingProcess=${
              filterData.pendingProcess
            }&branchActive=${
              filterData.active
            }&sort_by=${currentColumn}&direction=${
              direction ? "asc" : "desc"
            }&pagination=${+event.target.value}&page=${page}`
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRowsPerPage(parseInt(data.records.per_page));
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `netsuite/v1/getLocations?direction=${
              direction ? "asc" : "desc"
            }&pagination=${+event.target.value}&page=${1}`
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRowsPerPage(parseInt(data.records.per_page));
              setFrom(data.records.from);
              setTo(data.records.to);
              setPage(data.records.current_page);
              setLoading(false);
            }
          });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List Current Location (NetSuite)</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Location Code</InputLabel>
                    <TextField
                      variant="outlined"
                      name="branchCode"
                      value={filterData.branchCode}
                      onChange={handleChangeFilter}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleFilter();
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Location Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="branchName"
                      value={filterData.branchName}
                      onChange={handleChangeFilter}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleFilter();
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Inbound Location</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.inboundBranch}
                        // label="Exclusive Pending"
                        name="inboundBranch"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Y"}>Yes</MenuItem>
                        <MenuItem value={"N"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Pending Process</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.pendingProcess}
                        // label="Exclusive Pending"
                        name="pendingProcess"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Active</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.active}
                        name="active"
                        // label="Active"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Y"}>Yes</MenuItem>
                        <MenuItem value={"N"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.branchCode ||
        submittedData.branchName ||
        submittedData.pendingProcess ||
        submittedData.inboundBranch ||
        submittedData.active ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>
              {submittedData.branchCode && (
                <p>
                  <span>Location Code: {submittedData.branchCode}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("branchCode")}
                  />
                </p>
              )}
              {submittedData.branchName && (
                <p>
                  <span>Location Name: {submittedData.branchName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("branchName")}
                  />
                </p>
              )}

              {submittedData.inboundBranch && (
                <p>
                  <span>
                    Inbound Location:{" "}
                    {submittedData.inboundBranch === "Y" ? "Yes" : "No"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("inboundBranch")}
                  />
                </p>
              )}

              {submittedData.pendingProcess && (
                <p>
                  <span>
                    Pending Process:{" "}
                    {submittedData.pendingProcess === "1" ? "Yes" : "No"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("pendingProcess")}
                  />
                </p>
              )}
              {submittedData.active && (
                <p>
                  <span>
                    Active: {submittedData.active === "Y" ? "Yes" : "No"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("active")}
                  />
                </p>
              )}
            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}

        <Grid item xs={12}>
          <TableComponentBranch
            columns={columns}
            rows={rows}
            sort={true}
            handleView={handleView}
            handleSort={handleSort}
            loading={loading}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewBranchDialog viewDetails={viewDetails} sendDetails={sendDetails} />
      )}
    </div>
  );
};

export default NetsuiteCurrentBranch;
