import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import FormTextField from "./components/FormTextField";
import FormCheckbox from "./components/FormCheckbox";
import FormAutocomplete from "./components/FormAutocomplete";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PATH from "../../../Routing/PATHS";
import { httpclient } from "../../../utils/httpClient";
import { ExpandMore } from "@mui/icons-material";
import LoadingScreen from "../../../Components/LoadingScreen";
import MuiAlert from "@mui/material/Alert";
import featureServer from "../../../utils/featureServer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function convertCommaSeparatedToNewline(inputString) {
  if (!inputString) {
    return "";
  }
  const dataArray = inputString.split(",").filter(Boolean); // Filter out empty values
  return dataArray.join("\n");
}


const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const MissingStockCodesTable = (props) => {
  return (
    <TableContainer component={Paper} style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <Table aria-label="missing stock codes table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>SN</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {props.name === 'soh' ? 'Missing SOH' : 'Missing Stock Code'}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.length > 0 ? (
            props.rows.map((code, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{code}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2} style={{ textAlign: 'center', padding: '16px' }}>
                No Records Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>

  );
};

const MissingSKU = (props) => {
  console.log("p11", props);
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    success: false,
  });
  const [open, setOpen] = useState(false);
  const [loadingDismiss, setLoadingDismiss] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");


  const handleClose = () => {
    props.setMovefurther(false);
    props.setOpenSKU(false)
  };

  const handleYes = () => {
    props.setMovefurther(true);
    props.setExpanded("panel2");
    props.setOpenSKU(false)
  };

  const handleCloseSnack = () => {
    setOpen(false);
  };

  const handleStockDownload = () => {
    httpclient
      .get(featureServer ? `https://ubatemp.retailcare.com.au/php/export-all-missing-stock-codes-in-rep?stockCodes=${props.validated}` : `https://uba.synccare.com.au/php/export-all-missing-stock-codes-in-rep?stockCodes=${props.validated}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          var timestamp = new Date().getTime();
          link.setAttribute('download', `missing_stock_codes_${timestamp}.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        console.error("Error downloading file: ", error);
      });
  };

  const handleSOHDownload = () => {
    httpclient
      .get(featureServer ? `https://ubatemp.retailcare.com.au/php/export-soh-missing-stock-codes?stockCodes=${props.validated}` : `https://uba.synccare.com.au/php/export-soh-missing-stock-codes?stockCodes=${props.validated}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          var timestamp = new Date().getTime();
          link.setAttribute('download', `missing_soh_${timestamp}.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        console.error("Error downloading file: ", error);
      });
  };

  const handleDismiss = () => {
    setLoadingDismiss(true);
    httpclient
      .get(featureServer ? `https://ubatemp.retailcare.com.au/php/dismiss-stock-codes` : `https://uba.synccare.com.au/php/dismiss-stock-codes`)
      .then(({ data }) => {
        if (data.success) {
          setLoadingDismiss(false);
          setOpen(true);
          setMessage(data.message);
          setMessageState(data.status);
          setTimeout(() => {
            handleClose();
            props.handleClose();
          }, 1000)
        }
      })
      .catch((error) => {
        setLoadingDismiss(false);
        console.error("Error ", error);
      });
  };

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="md"
        sx={{ zIndex: 9999 }}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          All Missing Stock/SOH
        </StyledHeaderTitle>
        <DialogContent>
          {/* <Box pt={3} >Are you sure you want to cancel this order? </Box> */}
          <Box pt={3} >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Button sx={{ marginLeft: "48%", marginBottom: '5px', minWidth: "220px" }} onClick={handleStockDownload} variant="contained" >Download Stock Codes</Button>
                <MissingStockCodesTable rows={props.missingStockCodes} name="stock" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button sx={{ marginLeft: "65%", marginBottom: '5px', minWidth: "150px" }} onClick={handleSOHDownload} variant="contained" >Download SOH</Button>
                <MissingStockCodesTable rows={props.missingSoh} name="soh" />
              </Grid>
            </Grid>
          </Box>

        </DialogContent>
        <DialogActions style={{ margin: "5px 10px", justifyContent: "space-between" }}>
          {/* <Button
            onClick={handleDismiss}
            color="primary"
            variant="contained"
            autoFocus
            disabled={loadingDismiss}
          >
            Dismiss
          </Button> */}
          <span style={{ marginLeft: "20px", fontWeight: "600" }}>{(props.missingStockCodes.length === 0 && props.missingSoh.length === 0) ? `You dont have any missing SKU.` : `Do you wish to proceed further with these missing SKU?`}</span>

          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={handleYes}
              color="primary"
              variant="contained"
              autoFocus
            >
              Proceed
            </Button>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              autoFocus
            >
              Discard
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        sx={{ marginTop: "5px" }}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};



const MissingStocks = (props) => {

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    success: false,
  });
  const [open, setOpen] = useState(false);
  const [loadingDismiss, setLoadingDismiss] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");


  const handleClose = () => {
    props.setOpenMissingStocks(false)
  };

  const handleCloseSnack = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    httpclient
      .get(featureServer ? `https://ubatemp.retailcare.com.au/php/download-stock-codes` : `https://uba.synccare.com.au/php/download-missing-stock-codes`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'missing_stock_codes.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        console.error("Error downloading file: ", error);
      });
  };

  const handleDismiss = () => {
    setLoadingDismiss(true);
    httpclient
      .get(featureServer ? `https://ubatemp.retailcare.com.au/php/dismiss-stock-codes` : `https://uba.synccare.com.au/php/dismiss-stock-codes`)
      .then(({ data }) => {
        if (data.success) {
          setLoadingDismiss(false);
          setOpen(true);
          setMessage(data.message);
          setMessageState(data.status);
          setTimeout(() => {
            handleClose();
            props.handleClose();
          }, 1000)
        }
      })
      .catch((error) => {
        setLoadingDismiss(false);
        console.error("Error ", error);
      });
  };

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="md"
        sx={{ zIndex: 9999 }}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          All Missing Stock Codes
        </StyledHeaderTitle>
        <DialogContent>
          {/* <Box pt={3} >Are you sure you want to cancel this order? </Box> */}
          <Box pt={3} >
            <MissingStockCodesTable rows={props.missingRows} />

          </Box>

        </DialogContent>
        <DialogActions style={{ margin: "5px 10px", justifyContent: "space-between" }}>
          <Button
            onClick={handleDismiss}
            color="primary"
            variant="contained"
            autoFocus
            disabled={loadingDismiss}
          >
            Dismiss
          </Button>

          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={handleDownload}
              color="primary"
              variant="contained"
              autoFocus
            >
              Download All
            </Button>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              autoFocus
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        sx={{ marginTop: "5px" }}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const ReplenishmentForecast = ({ }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = {
    defaultDemand: [
      { id: 1, name: "MAX" },
      { id: 2, name: "MAXCAT" },
      { id: 3, name: "NORMAL" },
      { id: 4, name: "NORMALCAT" },
      { id: 5, name: "RECENT" },
      { id: 6, name: "HIST" },
    ],
    defaultForecast: [
      { id: 1, name: "EOQ" },
      { id: 2, name: "JIT" },
    ],
    smoothing: [
      { id: 1, name: "NONE" },
      { id: 2, name: "LIGHT" },
      { id: 3, name: "HEAVY" },
    ],
    startOfTheWeek: [
      { id: 1, name: "SUNDAY" },
      { id: 2, name: "MONDAY" },
    ],
    // excludeMonth: [
    //   { id: 1, name: "11" },
    //   { id: 2, name: "12" },
    // ],

  };

  const [showField, setShowField] = useState(false);

  const [filterData, setFilterData] = useState({
    allocationType: 'adjusted',
    supplierLeadTime: location.state ? location.state.filterData.supplierLeadTime : "6",
    custLeadTimeDays: location.state ? location.state.filterData.custLeadTimeDays : "14",
    stockCodes: "",
    customerID: "",
    coverageBuffer: location.state ? location.state.filterData.coverageBuffer : "0",
    cycleFrequency: location.state ? location.state.filterData.cycleFrequency : "1",
    defaultDemand: location.state ? location.state.filterData.defaultDemand : "NORMAL",
    defaultForecast: location.state ? location.state.filterData.defaultForecast : "JIT",
    smoothing: location.state ? location.state.filterData.smoothing : "LIGHT",
    recentSalesWeek: "4",
    useSalesDataSince: location.state ? location.state.filterData.useSalesDataSince : "365",
    historicalSalesStart: "2022-07-01",
    salesExcludeMonths: location.state ? location.state.filterData.salesExcludeMonths : "11,12",
    historicalSalesEnd: "2022-09-30",
    globalAllocRamping: location.state ? location.state.filterData.globalAllocRamping : "0",
    globalSales: location.state ? location.state.filterData.globalSales : "0.75",
    meanTrimming: location.state ? location.state.filterData.meanTrimming : "0",
    otherSettings: location.state ? location.state.filterData.otherSettings : "",
    endDate: location.state ? location.state.filterData.endDate : "",
    // importedDate: "",
    endDate: location.state ? location.state.filterData.endDate : "",
    startOfTheWeek: "",
    currentProductOnly: false,
    dataRetrieveOnly: false,
    ignoreSupplier: false,
    standardAllocations: false,
    adjustedAllocations: true,
    allocateToMSLOnly: false,
  });

  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [mappedLoading, setMappedLoading] = useState(false);
  /*Imported Date*/
  const [importedDateLoading, setImportedDateLoading] = useState(false);
  const [importedDateData, setImportedDateData] = useState([]);
  const [notFound, setNotFound] = useState([]);
  const [validated, setValidated] = useState(false);
  const [alertClose, setAlertClose] = useState(false);
  const [missingStockCodes, setMissingStockCodes] = useState([]);
  const [missingSoh, setMissingSoh] = useState([]);
  const [openMissingStocks, setOpenMissingStocks] = useState(false);
  const [movefurther, setMovefurther] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [openSKU, setOpenSKU] = useState(false);

  const openToolTip = () => {
    setOpenMissingStocks(true); // Clicking the file input to open file selection dialog
  };

  useEffect(() => {
    httpclient
      .get(`replenishment/stockCodes/getstockCodes`)
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setFilterData((prevFilterData) => ({
            ...prevFilterData,
            stockCodes: `${convertCommaSeparatedToNewline(
              data.data.stock_codes
            )}`,
          }));

          if (!!data.data.stock_codes === true) {
            setExpanded("panel");
          } else if (!!data.data?.stock_codes === false) {
            setExpanded("panel1");
          }
        } else {
          setLoading(false);
        }
      });
    //setImportedDateLoading(true);
    // httpclient.get(`replenishment/getStoreSohDateRange`).then(({ data }) => {
    //   if (data.success) {
    //     setImportedDateLoading(false);
    //     const dataModified = data.data.map((item, index) => ({
    //       id: index,
    //       name: `${item.updatedDate}: ${item.tempSessionId}`,
    //     }));
    //     setImportedDateData(dataModified);
    //     setFilterData((prevFilterData) => ({
    //       ...prevFilterData,
    //       importedDate: dataModified[0].name,
    //     }));
    //   } else {
    //     setImportedDateLoading(false);
    //   }
    // });
  }, []);

  // useEffect(() => {
  //   handleMappedStockCodes();
  //   const intervalId = setInterval(handleMappedStockCodes, 5 * 60 * 1000); // Fetch data every 60 seconds
  //   return () => clearInterval(intervalId);
  // }, []);

  // const handleMappedStockCodes = () => {
  //   setMappedLoading(true);
  //   httpclient
  //     .get(featureServer ? `https://ubatemp.retailcare.com.au/php/mapped-stock-codes` : `replenishment/mapped-stock-codes`)
  //     .then(({ data }) => {
  //       if (data.success) {
  //         setMappedLoading(false);
  //         if (data.view === 1 && data.not_found_stock_codes && data.not_found_stock_codes.length > 0) {
  //           setOpen(true);
  //           setMessageState("warning");
  //           setMessage(
  //             <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  //               <span>
  //                 These stock codes are not available in Job Line and NetSuite:
  //                 <span style={{ fontWeight: "600" }}>{data.not_found_stock_codes.slice(0, 10).join(", ")}</span>
  //               </span>
  //               {/* {data.not_found_stock_codes.length > 10 && */}
  //               <Button variant="contained" sx={{ width: "140px", marginLeft: "20px" }} onClick={() => openToolTip()}>View All</Button>
  //               {/* } */}
  //             </div>
  //           );
  //           setNotFound(data.not_found_stock_codes);
  //         }
  //       }
  //     });
  // };

  const handleValidate = () => {
    let stockCodes = formatStockCodes(filterData.stockCodes);
    setValidated(stockCodes);
    setFetchLoading(true);
    httpclient
      .get(featureServer ? `https://ubatemp.retailcare.com.au/php/missing-stock-codes-lists?stockCodes=${stockCodes}` : `https://uba.synccare.com.au/php/missing-stock-codes-lists?stockCodes=${stockCodes}`)
      .then(({ data }) => {
        setAlertClose(true);
        if (data.success) {
          setFetchLoading(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setMissingStockCodes(data.missingStockCodes);
          setMissingSoh(data.missingSoh);
          setTimeout(() => {
            setOpenSKU(true);
          }, 1500)
        } else {
          setFetchLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
          setMissingStockCodes([]);
          setMissingSoh([]);
          setTimeout(() => {
            setOpenSKU(true);
          }, 1500)
        }
      });
  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChangeFilter = (e) => {
    const { name, value, checked, type } = e.target;


    // Use a conditional to check the type of the input
    const inputValue = type === "checkbox" ? checked : value;
    if (name === "stockCodes") {
      setMovefurther(false);
      setFetchLoading(false);
    }
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      [name]: inputValue,
    }));
  };

  const handleChangeFilterRadio = (event) => {
    const { name, value } = event.target;

    if (name === 'allocationType') {
      const standardAllocations = value === 'standard';
      setFilterData({
        ...filterData,
        allocationType: value,
        standardAllocations,
        adjustedAllocations: !standardAllocations,
        globalSales: standardAllocations ? "0" : "0.75"
      });
    } else {
      setFilterData({
        ...filterData,
        [name]: value
      });
    }
  };



  // const handleChangeFilter = (e) => {
  //   const { name, value, checked, type } = e.target;

  //   // Use a conditional to check the type of the input
  //   let inputValue;
  //   if (name === "salesExcludeMonths") {
  //     // If the field is salesExcludeMonths and the type is text, split the comma-separated string into an array
  //     inputValue = type === "text" ? value.split(',') : [];
  //   } else {
  //     inputValue = type === "checkbox" ? checked : value;
  //   }

  //   setFilterData((prevFilterData) => ({
  //     ...prevFilterData,
  //     [name]: inputValue,
  //   }));
  // };


  const handleChangeAuto = (name, value, getOptionLabelFromAutocomplete) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      [name]: value !== null ? value[getOptionLabelFromAutocomplete] : "",
    }));
    if (name === "defaultDemand") {
      setShowField(false);
      setTimeout(() => {
        setShowField(true);
      }, 400);
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const formatStockCodes = (stockCodesString) => {
    const stockCodesArray = stockCodesString.split("\n");
    const formattedStockCodes = stockCodesArray.join(",");
    return formattedStockCodes;
  };
  console.log("movv", movefurther)
  return (
    <>
      {loading || importedDateLoading ? (
        <LoadingScreen message="Retrieving stock codes data..." />
      ) : (
        <>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel-1"
            >
              <Typography variant="h5">Enter Stock Codes</Typography>
              {/* {mappedLoading && (
                <>
                  <CircularProgress
                    style={{
                      height: "25px",
                      width: "25px",
                      marginLeft: "20px",
                      position: "relative",
                    }}
                  />
                  <Typography variant="h6">Fetching New Stocks..</Typography>
                </>
              )} */}


            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <FormTextField
                    label="Enter stock code to generate forecasting (1 stock code per line)"
                    name="stockCodes"
                    multiline={true}
                    rows={4}
                    value={filterData.stockCodes}
                    onChange={handleChangeFilter}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
            <Button sx={{ marginBottom: '10px', marginLeft: '20px' }} disabled={fetchLoading} onClick={handleValidate} variant="contained" >Validate SKU</Button>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2" && movefurther}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h5">List Main Parameter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container maxWidth="md">
                <Paper elevation={20}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ padding: 3 }}
                    maxWidth="md"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {/* <Grid item xs={12}>
                  <h1 style={{ margin: 0, padding: 0 }}>
                    List Main Parameters
                  </h1>
                </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Supplier Lead Time (in Weeks)"
                        type="number"
                        name="supplierLeadTime"
                        value={filterData.supplierLeadTime}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Cust Lead Time Days"
                        type="number"
                        name="custLeadTimeDays"
                        value={filterData.custLeadTimeDays}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
              <FormTextField
                label="Customer ID"
                type="text"
                name="customerID"
                value={filterData.customerID}
                onChange={handleChangeFilter}
                defaultValue={"TCS1"}
              />
            </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Coverage Buffer"
                        type="number"
                        name="coverageBuffer"
                        value={filterData.coverageBuffer}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Cycle Frequency"
                        type="number"
                        name="cycleFrequency"
                        value={filterData.cycleFrequency}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormAutocomplete
                        name="defaultDemand"
                        label="Default Demand"
                        options={data.defaultDemand}
                        getOptionLabel={"name"}
                        value={filterData.defaultDemand}
                        onChange={handleChangeAuto}
                        defaultValue={data.defaultDemand[2]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormAutocomplete
                        name="defaultForecast"
                        label="Default"
                        options={data.defaultForecast}
                        getOptionLabel={"name"}
                        value={filterData.defaultForecast}
                        onChange={handleChangeAuto}
                        defaultValue={data.defaultForecast[1]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormAutocomplete
                        name="smoothing"
                        label="Smoothing"
                        options={data.smoothing}
                        getOptionLabel={"name"}
                        value={filterData.smoothing}
                        onChange={handleChangeAuto}
                        defaultValue={data.smoothing[1]}
                      />
                    </Grid>
                    {filterData.defaultDemand === "RECENT" && (
                      <Grid item xs={12} sm={4}>
                        <FormTextField
                          label="Recent Sales Weeks"
                          type="number"
                          name="recentSalesWeek"
                          value={filterData.recentSalesWeek}
                          onChange={handleChangeFilter}
                          sx={{
                            transition:
                              "opacity 0.5s ease , background-color 0.5s ease, color 0.5s ease", // Transitions for a smoother effect
                            backgroundColor: showField ? "#D1EBDE" : "", // Apply styles dynamically
                          }}
                        />
                      </Grid>
                    )}
                    {filterData.defaultDemand === "HIST" && (
                      <Grid item xs={12} sm={4}>
                        <FormTextField
                          label="Historical Sales Start"
                          type="date"
                          name="historicalSalesStart"
                          value={filterData.historicalSalesStart}
                          onChange={handleChangeFilter}
                          sx={{
                            transition:
                              "opacity 0.5s ease , background-color 0.5s ease, color 0.5s ease", // Transitions for a smoother effect
                            backgroundColor: showField ? "#D1EBDE" : "", // Apply styles dynamically
                          }}
                        />
                      </Grid>
                    )}
                    {(filterData.defaultDemand === "" ||
                      filterData.defaultDemand === "NORMAL" ||
                      filterData.defaultDemand === "MAX" ||
                      filterData.defaultDemand === "MAXCAT" ||
                      filterData.defaultDemand === "NORMALCAT") && (
                        <Grid item xs={12} sm={4}>
                          {/* <FormTextField
                          label="Sales Exclude Months"
                          type="text"
                          name="salesExcludeMonths"
                          //value={filterData.salesExcludeMonths}
                          value={filterData.salesExcludeMonths.join(',')}
                          onChange={handleChangeFilter}
                          sx={{
                            transition:
                              "opacity 0.5s ease , background-color 0.5s ease, color 0.5s ease", // Transitions for a smoother effect
                            backgroundColor: showField ? "#D1EBDE" : "", // Apply styles dynamically
                          }}
                        /> */}
                          <FormTextField
                            label="Sales Exclude Months"
                            type="text"
                            name="salesExcludeMonths"
                            value={filterData.salesExcludeMonths} // Convert array of objects to comma-separated string
                            onChange={handleChangeFilter}
                            sx={{
                              transition: "opacity 0.5s ease , background-color 0.5s ease, color 0.5s ease", // Transitions for a smoother effect
                              backgroundColor: showField ? "#D1EBDE" : "", // Apply styles dynamically
                            }}
                          />


                        </Grid>
                      )}
                    {filterData.defaultDemand === "HIST" && (
                      <Grid item xs={12} sm={4}>
                        <FormTextField
                          label="Historical Sales End"
                          type="date"
                          name="historicalSalesEnd"
                          value={filterData.historicalSalesEnd}
                          onChange={handleChangeFilter}
                          sx={{
                            transition:
                              "opacity 0.5s ease , background-color 0.5s ease, color 0.5s ease", // Transitions for a smoother effect
                            backgroundColor: showField ? "#D1EBDE" : "", // Apply styles dynamically
                          }}
                        />
                      </Grid>
                    )}
                    {filterData.defaultDemand !== "HIST" && (
                      <Grid item xs={12} sm={4}>
                        <FormTextField
                          label="Use Sales Data Since (in Days)"
                          type="number"
                          name="useSalesDataSince"
                          value={filterData.useSalesDataSince}
                          onChange={handleChangeFilter}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Global Alloc Ramping"
                        type="number"
                        name="globalAllocRamping"
                        //disabled={true}
                        value={filterData.globalAllocRamping}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Global Sales"
                        type="number"
                        name="globalSales"
                        disabled={filterData.standardAllocations}
                        value={filterData.globalSales}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Mean Trimming"
                        type="number"
                        name="meanTrimming"
                        value={filterData.meanTrimming}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                      <FormAutocomplete
                        name="startOfTheWeek"
                        label="Start Of The Week"
                        options={data.startOfTheWeek}
                        getOptionLabel={"name"}
                        value={filterData.startOfTheWeek}
                        onChange={handleChangeAuto}
                        defaultValue={data.startOfTheWeek[0]}
                      />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={4}>
                      <FormAutocomplete
                        name="importedDate"
                        label="Imported Date"
                        options={importedDateData}
                        getOptionLabel={"name"}
                        value={filterData.importedDate}
                        onChange={handleChangeAuto}
                        defaultValue={importedDateData[0]}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="From Date"
                        type="date"
                        name="endDate"
                        value={filterData.endDate}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Other Settings"
                        type="text"
                        name="otherSettings"
                        value={filterData.otherSettings}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        sx={{ marginTop: { sm: 3, xs: 0 } }}
                        onClick={() =>
                          setFilterData({ ...filterData, otherSettings: "" })
                        }
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>{" "}
                    <Grid item xs={12} sm={4}></Grid>{" "}
                    {/* {filterData.defaultDemand === "" && (
                      <>
                        <Grid item xs={12} sm={4}></Grid>
                      </>
                    )}

                    {(filterData.defaultDemand === "NORMAL" ||
                      filterData.defaultDemand === "NORMALCAT" ||
                      filterData.defaultDemand === "MAX" ||
                      filterData.defaultDemand === "MAXCAT" ||
                      filterData.defaultDemand === "RECENT") && (
                      <Grid item xs={12} sm={4}></Grid>
                    )} */}
                    {/* <Grid item xs={12} sm={6}>
                      <FormCheckbox
                        label="Current Product Only"
                        name="currentProductOnly"
                        checked={filterData.currentProductOnly}
                        onChange={handleChangeFilter}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <RadioGroup
                        aria-label="allocationType"
                        name="allocationType"
                        value={filterData.allocationType}
                        onChange={handleChangeFilterRadio}
                      >
                        <FormControlLabel
                          value="standard"
                          control={<Radio />}
                          label="Standard Allocations"
                        />
                        <FormControlLabel
                          value="adjusted"
                          control={<Radio />}
                          label="Adjusted Allocations"
                        />
                      </RadioGroup>
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                      <FormCheckbox
                        label="Data Retrieve Only"
                        checked={filterData.dataRetrieveOnly}
                        onChange={handleChangeFilter}
                        name="dataRetrieveOnly"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormCheckbox
                        label="Ignore Supplier"
                        checked={filterData.ignoreSupplier}
                        onChange={handleChangeFilter}
                        name="ignoreSupplier"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormCheckbox
                        label="Allocate to MSL Only"
                        checked={filterData.allocateToMSLOnly}
                        onChange={handleChangeFilter}
                        name="allocateToMSLOnly"
                      />
                    </Grid> */}
                  </Grid>

                  <Grid container spacing={2} sx={{ padding: 3 }}>
                    <Grid item xs={12} textAlign="right">
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          padding: 2,
                          marginTop: { xs: 1 },
                          paddingX: 4,
                          width: { xs: "100%", lg: "auto" },
                        }}
                      >
                        Clear Allocation
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          padding: 2,
                          marginTop: { xs: 1 },
                          paddingX: 4,
                          marginLeft: { xs: 0, lg: 4 },
                          width: { xs: "100%", lg: "auto" },
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="contained"
                        size="large"
                        disabled={mappedLoading}
                        sx={{
                          padding: 2,
                          marginTop: { xs: 1 },
                          paddingX: 4,
                          marginLeft: { xs: 0, lg: 4 },
                          width: { xs: "100%", lg: "auto" },
                        }}
                        onClick={(event) => {
                          localStorage.setItem("passAPI", true);
                          if (filterData.adjustedAllocations)
                            navigate(PATH.REPLENISHMENT_ALLOCATIONS_ADJUSTED, {
                              state: {
                                filterData: filterData,
                                from: "adjusted"
                              },
                              target: "_blank",
                            });
                          <Link
                            to={{
                              pathname: PATH.REPLENISHMENT_ALLOCATIONS_ADJUSTED,
                              state: {
                                filterData: filterData,
                              },
                            }}
                            target="_blank"
                          />;
                          if (filterData.standardAllocations)
                            navigate(PATH.REPLENISHMENT_ALLOCATIONS_ADJUSTED, {
                              state: {
                                filterData: filterData,
                              },
                            });
                        }}
                      >
                        Run Allocation
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Container>
            </AccordionDetails>
          </Accordion>
          {openMissingStocks &&
            <MissingStocks
              missingRows={notFound}
              setOpenMissingStocks={setOpenMissingStocks}
              handleClose={handleClose}
            />
          }
          {openSKU &&
            <MissingSKU
              missingStockCodes={missingStockCodes}
              missingSoh={missingSoh}
              validated={validated}
              setMovefurther={setMovefurther}
              setExpanded={setExpanded}
              setOpenSKU={setOpenSKU}
              handleClose={handleClose}
            />
          }
          <Snackbar
            autoHideDuration={alertClose ? 3000 : null}
            anchorOrigin={{ vertical: "top", horizontal: alertClose ? "right" : "center" }}
            open={open}
            sx={{ marginTop: "65px" }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={messageState}
              sx={{ width: "100%" }}
            // sx={{
            //   width: "100%",
            //   maxHeight: "8vh",
            //   overflowY: "auto",
            //   display: "flex",
            //   flexDirection: "row",
            // }}
            >
              {message}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
};

export default ReplenishmentForecast;
